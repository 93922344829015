import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import { ref, onMounted } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    let client = ref("");
    onMounted(() => {
      getClient();
    });
    //判断客户端进行适配
    function getClient() {
      var userAgent = navigator.userAgent,
        platform = navigator.platform,
        macOS = /Mac OS X/.test(userAgent),
        windows = /win16|win32|win64|win/i.test(platform),
        ios = /iphone|ipad|ipod/.test(userAgent) && !macOS,
        android = /android/i.test(userAgent);
      if (macOS) client.value = "Mac OS";else if (windows) client.value = "Windows";else if (ios) client.value = "iOS";else if (android) client.value = "Android";else client.value = "unknown";
      window.localStorage.setItem("ch_platform", client.value);
    }
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_unref(ElConfigProvider), {
        locale: _unref(zhCn)
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};