import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex",
    "margin-bottom": "20px"
  }
};
const _hoisted_2 = {
  class: "mobile-list-item-title"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  style: {
    "white-space": "nowrap",
    "overflow": "hidden",
    "text-overflow": "ellipsis"
  }
};
const _hoisted_6 = {
  class: "mobile-list-item-title"
};
const _hoisted_7 = ["href"];
const _hoisted_8 = {
  key: 3
};
export default {
  __name: 'mobileList',
  props: {
    dataList: Array,
    columns: Array,
    isDelete: {
      type: Boolean,
      default: false
    },
    isConfirm: {
      type: Boolean,
      default: false
    },
    isAgentConfirm: {
      type: Boolean,
      default: false
    },
    isDownload: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    confirmTixi: {
      type: Boolean,
      default: false
    },
    fialTixi: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    account: {
      type: Object,
      default: {}
    }
  },
  emits: ["deleteById", "doShowSmsBack", "doShowSmsAgent", "confirm", "showFailDialog", "doShowAddCal", "preview"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const $emit = __emit;
    function doShowAddCal() {
      $emit("doShowAddCal");
    }
    function preview(row) {
      $emit("preview", row);
    }
    function download(access_path) {
      $emit("download", access_path);
    }
    function confirm(id) {
      $emit("confirm", id);
    }
    function showFailDialog(row) {
      $emit("showFailDialog", row);
    }
    function deleteById(id) {
      $emit("deleteById", id);
    }
    function doShowSmsBack(row) {
      $emit("doShowSmsBack", row);
    }
    function doShowSmsAgent(row) {
      $emit("doShowSmsAgent", row);
    }
    function showEdit(row) {
      $emit("showEdit", row);
    }
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.dataList.obj, (i, index) => {
        return _openBlock(), _createElementBlock("div", {
          style: {
            "background": "#fff",
            "margin-bottom": "10px",
            "padding": "10px"
          },
          key: index
        }, [_createElementVNode("div", _hoisted_1, [__props.isConfirm ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          size: "small",
          type: "primary",
          text: "",
          link: "",
          icon: "user",
          onClick: $event => doShowSmsBack(i)
        }, {
          default: _withCtx(() => [_createTextVNode("回话")]),
          _: 2
        }, 1032, ["onClick"])) : _createCommentVNode("", true), __props.isAgentConfirm ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          size: "small",
          type: "primary",
          text: "",
          link: "",
          icon: "edit",
          onClick: $event => doShowSmsAgent(i)
        }, {
          default: _withCtx(() => [_createTextVNode("管理员回信")]),
          _: 2
        }, 1032, ["onClick"])) : _createCommentVNode("", true)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.columns, (k, index1) => {
          return _openBlock(), _createElementBlock("div", {
            class: "mobile-list-item",
            key: index1
          }, [_createElementVNode("span", _hoisted_2, _toDisplayString(k.title) + "：", 1), k.type == 'img' ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: i[k.name],
            style: {
              "width": "32px",
              "vertical-align": "middle"
            }
          }, null, 8, _hoisted_3)) : _createCommentVNode("", true), k.name == 'sms_back' ? (_openBlock(), _createElementBlock("span", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i[k.name].slice().reverse(), (j, index) => {
            return _openBlock(), _createElementBlock("div", {
              key: index,
              style: {
                "margin-bottom": "10px"
              }
            }, [_createElementVNode("p", _hoisted_5, _toDisplayString(j.text), 1), _createElementVNode("span", _hoisted_6, _toDisplayString(j.create_dtme), 1)]);
          }), 128))])) : k.name == 'access_path' ? (_openBlock(), _createBlock(_component_el_button, {
            key: 2,
            text: "",
            link: "",
            icon: "download"
          }, {
            default: _withCtx(() => [_createElementVNode("a", {
              href: i.access_path
            }, "下载", 8, _hoisted_7)]),
            _: 2
          }, 1024)) : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(k.name == "discount" && i[k.name] ? i[k.name] + "%" : i[k.name]), 1))]);
        }), 128)), __props.isDelete ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "danger",
          size: "small",
          icon: "delete",
          text: "",
          link: "",
          onClick: $event => deleteById(i._id)
        }, {
          default: _withCtx(() => [_createTextVNode("删除")]),
          _: 2
        }, 1032, ["onClick"])) : _createCommentVNode("", true), __props.isEdit ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          type: "primary",
          size: "small",
          icon: "edit",
          onClick: $event => showEdit(i)
        }, {
          default: _withCtx(() => [_createTextVNode("修改信息")]),
          _: 2
        }, 1032, ["onClick"])) : _createCommentVNode("", true), __props.isDownload ? (_openBlock(), _createBlock(_component_el_button, {
          key: 2,
          text: "",
          link: "",
          icon: "download",
          onClick: $event => download(i.access_path)
        }, {
          default: _withCtx(() => [_createTextVNode(" 下载 ")]),
          _: 2
        }, 1032, ["onClick"])) : _createCommentVNode("", true), __props.isPreview && i.file_name.split('.').pop() !== 'rar' && i.file_name.split('.').pop() !== 'zip' ? (_openBlock(), _createBlock(_component_el_button, {
          key: 3,
          text: "",
          link: "",
          icon: "view",
          onClick: $event => preview(i)
        }, {
          default: _withCtx(() => [_createTextVNode("预览")]),
          _: 2
        }, 1032, ["onClick"])) : _createCommentVNode("", true)]);
      }), 128))]);
    };
  }
};