import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, onMounted, reactive } from "vue";
import { myMixin } from "../mixin.js";
export default {
  __name: 'doc_account',
  setup(__props) {
    const {
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    let account = reactive({
      obj: {}
    });
    onMounted(() => {
      account.obj = JSON.parse(window.sessionStorage.getItem("doc_account")).account;
    });
    async function saveAccount() {
      let res = await withLoading(apiAll(account.obj, "/doc/update/" + account.obj._id));
      if (res.code && res.code < 0) {
        errorMsgBox(res.msg);
      } else {
        successMsgBox("修改成功");
      }
    }
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_card = _resolveComponent("el-card");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_card, {
        "body-style": {
          width: '50%'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "180px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "账户名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(account).obj.account_name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(account).obj.account_name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "账户密码"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(account).obj.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(account).obj.password = $event),
              type: "password"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              onClick: saveAccount,
              icon: "select"
            }, {
              default: _withCtx(() => [_createTextVNode("保存")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }
};