import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3c674f1e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-template-623",
  style: {
    "{\n                overflow": "hidden",
    "background-color": "#f4f4f4",
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  style: {
    "width": "60%",
    "margin-right": "10px"
  }
};
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  style: {
    "min-width": "40px",
    "font-weight": "bold",
    "color": "red",
    "text-align": "left",
    "margin-right": "2px"
  }
};
const _hoisted_7 = {
  style: {
    "margin-right": "2px"
  }
};
const _hoisted_8 = {
  style: {
    "color": "blue",
    "margin-right": "2px"
  }
};
const _hoisted_9 = {
  style: {
    "font-weight": "bold",
    "color": "green"
  }
};
const _hoisted_10 = {
  style: {
    "font-weight": "bold",
    "margin-right": "10px",
    "text-align": "right"
  }
};
const _hoisted_11 = ["src"];
const _hoisted_12 = {
  style: {
    "font-weight": "bold",
    "color": "green"
  }
};
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  style: {
    "font-weight": "bold",
    "color": "green"
  }
};
const _hoisted_15 = {
  style: {
    "font-weight": "bold",
    "color": "green"
  }
};
const _hoisted_16 = ["src"];
const _hoisted_17 = {
  key: 0,
  style: {
    "font-weight": "bold",
    "color": "blue"
  }
};
const _hoisted_18 = {
  key: 1,
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_19 = {
  key: 2,
  style: {
    "font-weight": "bold",
    "color": "red"
  }
};
const _hoisted_20 = {
  key: 0,
  style: {
    "font-weight": "bold",
    "color": "green"
  }
};
const _hoisted_21 = {
  key: 1
};
const _hoisted_22 = ["src"];
const _hoisted_23 = {
  style: {
    "font-weight": "bold",
    "color": "green"
  }
};
const _hoisted_24 = {
  style: {
    "font-weight": "bold",
    "color": "red"
  }
};
const _hoisted_25 = {
  style: {
    "font-weight": "bold",
    "color": "green"
  }
};
const _hoisted_26 = {
  key: 0,
  style: {
    "height": "30px",
    "line-height": "30px"
  }
};
import { ref, reactive, onMounted } from 'vue';
import { myMixin } from '../mixin.js';
export default {
  __name: 'userIndex',
  setup(__props) {
    const {
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      account,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    let circleImg = ref(require('../assets/1.png'));
    let circleImg1 = ref(require('../assets/2.png'));
    let activeName = ref('订单客户');
    let loadingCol = ref(false);
    let currentRow = reactive({
      obj: {}
    });
    let selectedFields = reactive({
      obj: []
    });
    let fieldsShow = ref(false);
    let exportType = ref(1);
    let dangerImg = require('../assets/危险.png');
    let reqData = reactive({
      obj: {
        url: '/docUser/getUser',
        data: {
          name: '',
          agent_id: ''
        }
      }
    });
    let columns = reactive([{
      name: 'finishInfo',
      title: '完成情况'
    }, {
      name: 'order_no',
      title: '订单号'
    }, {
      name: 'user_name',
      title: '姓名'
    }, {
      name: 'city',
      title: '省份'
    }, {
      name: 'company',
      title: '工作单位'
    }, {
      name: 'position',
      title: '目标职称'
    }, {
      name: 'review_dtme',
      title: '评审时间'
    }, {
      name: 'employ_requirement',
      title: '收录要求'
    }, {
      name: 'article_num',
      title: '篇数'
    }, {
      name: 'price',
      title: '价格（/篇）'
    }, {
      name: 'notice',
      title: '备注'
    },
    // {
    //   name: "user_status",
    //   title: "状态",
    // },
    {
      name: 'create_dtme',
      title: '登记时间'
    }]);
    let imgList = reactive({
      obj: []
    });
    let activeNames = ['0', '1', '2'];
    let dataList = reactive({
      obj: []
    });
    let smsWayShow = ref(false);
    onMounted(() => {
      doGetData();
    });
    function showEdit(row) {
      imgList.obj.push[{
        url: row.access_path
      }];
      currentRow.obj = row;
      smsWayShow.value = true;
    }
    function newWenan() {
      currentRow.obj = {};
      imgList.obj = [];
      currentRow.obj.agent_id = account._id;
      smsWayShow.value = true;
    }

    // async function doGetData() {
    //   let data = { agent_id: account._id };
    //   const res = await withLoading(apiAll(data, "/smsWay/getSmsWays"));
    //   dataList.obj = res.items;
    //   page.obj = res.page;
    // }

    async function doGetData(pane) {
      let val = activeName.value;
      if (pane) {
        val = pane.paneName;
      }
      if (val == '订单客户') {
        reqData.obj.url = '/docUser/getUser';
      } else if (val == '写手') {
        reqData.obj.url = '/docAuthor/getAuthorForIndex';
      } else {
        reqData.obj.url = '/doc/getContributeForIndex';
      }
      loadingCol.value = true;
      const res = await getData(reqData.obj);
      loadingCol.value = false;
      if (res.items) {
        dataList.obj = res.items;
        page.obj = res.page;
      } else {
        dataList.obj = res;
        page.obj = '';
      }
    }
    async function deleteById(id) {
      let data = {};
      const res = await withLoading(apiAll(data, '/docUser/delete/' + id));
      doGetData();
    }
    async function exportUser() {
      if (!selectedFields.obj || selectedFields.obj.length <= 0) {
        errorMsgBox('导出字段不能为空');
      } else {
        let data = {};
        data.selectedFields = selectedFields.obj;
        if (exportType.value == 1) {
          //导出当前，给出参数
          data.name = reqData.obj.data.name;
        }
        const res = await withLoading(apiAll(data, '/docExcel/addUserExcel'));
        if (res.code && res.code < 0) {
          errorMsgBox(res.code);
        } else {
          successMsgBox('导出成功');
          window.open(res.access_path);
        }
      }
    }
    async function addSmsWay() {
      if (!currentRow.obj.user_name) {
        errorMsgBox('姓名不能为空！');
        return false;
      }
      if (!currentRow.obj.company) {
        errorMsgBox('工作单位不能为空！');
        return false;
      }
      let formData = new FormData();
      if (imgList.obj && imgList.obj.length > 0) {
        formData.append('img', imgList.obj[0].raw);
      }
      formData.append('params', JSON.stringify(currentRow.obj));
      const res = await withLoading(formDataApi(formData, '/docUser/addUser'));
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        smsWayShow.value = false;
        doGetData();
      }
    }

    // async function updateSmsWay() {
    //   const res = await withLoading(
    //     apiAll(currentRow.obj, "/smsWenan/updateSmsWay/" + id)
    //   );
    //   doGetData();
    // }

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      const _component_el_collapse_item = _resolveComponent("el-collapse-item");
      const _component_el_collapse = _resolveComponent("el-collapse");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        gutter: 5,
        style: {
          margin: '15px 0 15px 0'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 5 : 24,
          style: {
            "display": "flex",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_input, {
            placeholder: "输入名称",
            style: _normalizeStyle({
              height: _unref(agent).obj == 'Windows' ? '33px' : '22px'
            }),
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            modelValue: _unref(reqData).obj.data.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(reqData).obj.data.name = $event),
            clearable: "",
            onKeyup: _cache[1] || (_cache[1] = _withKeys($event => doGetData(), ["enter"])),
            onClear: _cache[2] || (_cache[2] = $event => _unref(clearSearch)(_unref(reqData), doGetData))
          }, null, 8, ["style", "size", "modelValue"])]), _createVNode(_component_el_button, {
            icon: "search",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[3] || (_cache[3] = $event => doGetData())
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 14 : 24,
          style: {
            "text-align": "left",
            "margin-bottom": "10px"
          }
        }, null, 8, ["span"])]),
        _: 1
      }), _createElementVNode("div", null, [_createVNode(_component_el_tabs, {
        modelValue: _unref(activeName),
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _isRef(activeName) ? activeName.value = $event : activeName = $event),
        class: "demo-tabs",
        onTabClick: doGetData
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "订单客户",
          name: "订单客户"
        }), _createVNode(_component_el_tab_pane, {
          label: "写手",
          name: "写手"
        }), _createVNode(_component_el_tab_pane, {
          label: "样刊发表统计",
          name: "样刊发表统计"
        })]),
        _: 1
      }, 8, ["modelValue"]), _unref(activeName) == '订单客户' ? _withDirectives((_openBlock(), _createBlock(_component_el_collapse, {
        key: 0,
        modelValue: _unref(activeNames),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(activeNames) ? activeNames.value = $event : activeNames = $event),
        onChange: _ctx.handleChange
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList).obj, (i, index) => {
          return _openBlock(), _createBlock(_component_el_collapse_item, {
            key: index,
            name: index
          }, {
            title: _withCtx(() => [i.is_emergent && i.order_status !== '完成' ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _unref(dangerImg),
              style: {
                "width": "20px",
                "height": "20px",
                "vertical-align": "middle"
              }
            }, null, 8, _hoisted_4)) : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _unref(circleImg),
              style: {
                "height": "18px",
                "vertical-align": "middle"
              }
            }, null, 8, _hoisted_5)), _createElementVNode("span", {
              style: _normalizeStyle([{
                "font-weight": "bold",
                "margin-right": "2px"
              }, {
                color: i.order_status == '完成' ? 'green' : i.finishInfo.split('-')[0] == i.finishInfo.split('-')[1] ? 'purple' : 'blue'
              }])
            }, " 【" + _toDisplayString(i.finishInfo) + "】 ", 5), _createElementVNode("span", _hoisted_6, _toDisplayString(i.user_name), 1), _createElementVNode("span", _hoisted_7, _toDisplayString(i.review_dtme), 1), _createElementVNode("span", _hoisted_8, _toDisplayString(i.article_num) + "篇", 1), _createElementVNode("span", _hoisted_9, _toDisplayString(i.city), 1)]),
            default: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_10, _toDisplayString(i.position), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.authors, (k, index1) => {
              return _openBlock(), _createElementBlock("div", {
                key: index1,
                style: {
                  "margin-left": "20px"
                }
              }, [_createElementVNode("img", {
                src: _unref(circleImg1),
                style: {
                  "height": "13px",
                  "margin-right": "10px",
                  "vertical-align": "middle"
                }
              }, null, 8, _hoisted_11), _createElementVNode("span", null, [_createElementVNode("span", _hoisted_12, _toDisplayString(k.author_name), 1), _createTextVNode(" 【共" + _toDisplayString(k.articles.length) + " 篇】 ", 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(k.articles, (j, index2) => {
                return _openBlock(), _createElementBlock("div", {
                  key: index2,
                  style: {
                    "margin-left": "40px"
                  }
                }, [_createElementVNode("img", {
                  src: _unref(circleImg1),
                  style: {
                    "height": "13px",
                    "margin-right": "10px",
                    "vertical-align": "middle"
                  }
                }, null, 8, _hoisted_13), _createElementVNode("span", _hoisted_14, [_createElementVNode("span", _hoisted_15, _toDisplayString(j.title), 1), _createElementVNode("span", {
                  style: _normalizeStyle({
                    color: j.isFinish ? 'green' : 'red'
                  })
                }, "【" + _toDisplayString(j.isFinish ? '已完成' : '未完成') + "】", 5), _createElementVNode("span", {
                  style: _normalizeStyle({
                    color: j.is_pubish == '是' ? 'green' : 'red'
                  })
                }, " 【" + _toDisplayString(j.is_pubish == '是' ? '已出刊邮寄' : '未出刊邮寄') + "】 ", 5), _createElementVNode("span", {
                  style: _normalizeStyle({
                    color: j.is_data_check == '是' ? 'green' : 'red'
                  })
                }, " 【" + _toDisplayString(j.is_data_check == '是' ? '已检索' : '未检索') + "】 ", 5)])]);
              }), 128))])]);
            }), 128))])]),
            _: 2
          }, 1032, ["name"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "onChange"])), [[_directive_loading, _unref(loadingCol)]]) : _withDirectives((_openBlock(), _createBlock(_component_el_collapse, {
        key: 1,
        onChange: _ctx.handleChange
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList).obj, (i, index) => {
          return _openBlock(), _createBlock(_component_el_collapse_item, {
            key: index,
            name: index
          }, {
            title: _withCtx(() => [_createElementVNode("img", {
              src: _unref(circleImg),
              style: {
                "height": "18px",
                "vertical-align": "middle"
              }
            }, null, 8, _hoisted_16), i.finishInfo ? (_openBlock(), _createElementBlock("span", _hoisted_17, "【" + _toDisplayString(i.finishInfo) + "】", 1)) : _createCommentVNode("", true), i.author_name ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(i.author_name), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_19, "【" + _toDisplayString(i.journal_name) + "(" + _toDisplayString(i.articles.length) + ")】", 1))]),
            default: _withCtx(() => [_createElementVNode("span", null, [i.author_name ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(i.author_name), 1)) : _createCommentVNode("", true), i.author_name ? (_openBlock(), _createElementBlock("span", _hoisted_21, "【共" + _toDisplayString(i.articles.length) + " 篇】", 1)) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.articles, (j, index2) => {
              return _openBlock(), _createElementBlock("div", {
                key: index2,
                style: {
                  "margin-left": "40px"
                }
              }, [_createElementVNode("img", {
                src: _unref(circleImg1),
                style: {
                  "height": "13px",
                  "margin-right": "10px",
                  "vertical-align": "middle"
                }
              }, null, 8, _hoisted_22), _createElementVNode("span", _hoisted_23, [_createElementVNode("span", _hoisted_24, "【 " + _toDisplayString(j.userName) + "】", 1), _createElementVNode("span", _hoisted_25, _toDisplayString(j.title), 1), i.journal_name ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                style: _normalizeStyle({
                  color: j.isPublish ? 'green' : 'red'
                })
              }, "【" + _toDisplayString(j.isPublish ? '已邮寄' : '未邮寄') + "】", 5)) : (_openBlock(), _createElementBlock("span", {
                key: 1,
                style: _normalizeStyle({
                  color: j.isFinish ? 'green' : 'red'
                })
              }, "【" + _toDisplayString(j.isFinish ? '已完成' : '未完成') + "】", 5))])]);
            }), 128))])]),
            _: 2
          }, 1032, ["name"]);
        }), 128))]),
        _: 1
      }, 8, ["onChange"])), [[_directive_loading, _unref(loadingCol)]])])]), _unref(page) && _unref(page).obj ? (_openBlock(), _createElementBlock("div", _hoisted_26, [_createVNode(_component_el_pagination, {
        total: _unref(page).obj.totalResult,
        "page-size": _unref(page).obj.pageSize,
        "current-page": _unref(page).obj.startPage,
        background: "",
        small: "",
        "page-sizes": [3, 6, 9, 12, 15, 20, 30],
        layout: "sizes,prev, pager, next",
        onSizeChange: _cache[6] || (_cache[6] = $event => _unref(sizeChange)($event, doGetData, _unref(reqData).obj)),
        onCurrentChange: _cache[7] || (_cache[7] = $event => _unref(currentChange)($event, doGetData, _unref(reqData).obj))
      }, null, 8, ["total", "page-size", "current-page"])])) : _createCommentVNode("", true)]);
    };
  }
};