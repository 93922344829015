import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-44c93864"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-template-623",
  style: {
    "{\n                overflow": "hidden",
    "background-color": "#f4f4f4",
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  style: {
    "width": "60%",
    "margin-right": "10px"
  }
};
const _hoisted_4 = {
  style: {
    "height": "30px",
    "line-height": "30px"
  }
};
import { ref, reactive, onMounted } from 'vue';
import { myMixin } from '../mixin.js';
export default {
  __name: 'doc_notice',
  setup(__props) {
    const {
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      account,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    let currentRow = reactive({
      obj: {}
    });
    let reqData = reactive({
      obj: {
        url: '/docExcel/getNotice',
        data: {
          name: '',
          agent_id: ''
        }
      }
    });
    let wenanType = reactive(['关于平台', '人工客服', '合伙加盟']);
    let columns = reactive([{
      name: 'notice_type',
      title: '提醒类型'
    }, {
      name: 'notice_content',
      title: '提醒内容'
    }, {
      name: 'create_dtme',
      title: '创建时间'
    }]);
    let imgList = reactive({
      obj: []
    });
    let dataList = reactive({
      obj: []
    });
    let editShow = ref(false);
    let smsWayShow = ref(false);
    onMounted(() => {
      doGetData();
    });
    function showEdit(row) {
      imgList.obj.push[{
        url: row.access_path
      }];
      currentRow.obj = row;
      editShow.value = true;
    }
    function newWenan() {
      currentRow.obj = {};
      imgList.obj = [];
      currentRow.obj.agent_id = account._id;
      smsWayShow.value = true;
    }
    // async function doGetData() {
    //   let data = { agent_id: account._id };
    //   const res = await withLoading(apiAll(data, "/smsWay/getSmsWays"));
    //   dataList.obj = res.items;
    //   page.obj = res.page;
    // }

    async function doGetData() {
      const res = await getData(reqData.obj);
      dataList.obj = res.items;
      page.obj = res.page;
    }
    async function deleteById(id) {
      let data = {};
      const res = await withLoading(apiAll(data, '/docExcel/delete/' + id));
      doGetData();
    }
    function download(access_path) {
      window.location.href = access_path;
    }
    async function addSmsWay() {
      if (!currentRow.obj.phone) {
        errorMsgBox('手机号不能为空！');
        return false;
      }
      if (!currentRow.obj.passport) {
        errorMsgBox('密码不能为空！');
        return false;
      }
      let formData = new FormData();
      if (imgList.obj && imgList.obj.length > 0) {
        formData.append('img', imgList.obj[0].raw);
      }
      formData.append('params', JSON.stringify(currentRow.obj));
      const res = await withLoading(formDataApi(formData, '/doc/addUser'));
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        smsWayShow.value = false;
        editShow.value = false;
        doGetData();
      }
    }

    // async function updateSmsWay() {
    //   const res = await withLoading(
    //     apiAll(currentRow.obj, "/smsWenan/updateSmsWay/" + id)
    //   );
    //   doGetData();
    // }

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_mobileList = _resolveComponent("mobileList");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        gutter: 5,
        style: {
          margin: '15px 0 15px 0'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 5 : 24,
          style: {
            "display": "flex",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_input, {
            placeholder: "输入名称",
            style: _normalizeStyle({
              height: _unref(agent).obj == 'Windows' ? '33px' : '22px'
            }),
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            modelValue: _unref(reqData).obj.data.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(reqData).obj.data.name = $event)
          }, null, 8, ["style", "size", "modelValue"])]), _createVNode(_component_el_button, {
            icon: "search",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[1] || (_cache[1] = $event => doGetData())
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 14 : 24,
          style: {
            "text-align": "left",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "Delete",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "danger",
            onClick: _cache[2] || (_cache[2] = $event => _unref(clearSearch)(_unref(reqData), doGetData))
          }, {
            default: _withCtx(() => [_createTextVNode("清除")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _unref(agent).obj == 'Windows' ? (_openBlock(), _createBlock(_component_el_table, {
        key: 0,
        data: _unref(dataList).obj,
        class: _normalizeClass(_ctx.tableClassNames),
        ref: "table",
        size: _ctx.tableSize,
        "min-height": "100",
        border: false,
        "highlight-current-row": true,
        "max-height": _unref(tableMaxHeight),
        style: {
          "width": "100%"
        },
        showHeader: true,
        onSelectionChange: _ctx.handleSelectionChange,
        "header-cell-style": {
          background: '#eef1f6',
          color: '#606266'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          key: "6431",
          "show-overflow-tooltip": true,
          align: "center",
          look: "6431",
          "min-width": "180px",
          "column-key": "6431",
          prop: "notice_type",
          width: "auto",
          class: "itemTrue",
          label: "提醒类型"
        }), _createVNode(_component_el_table_column, {
          key: "6432",
          "show-overflow-tooltip": true,
          align: "center",
          look: "6432",
          "min-width": "180px",
          "column-key": "6432",
          prop: "notice_content",
          width: "auto",
          class: "itemTrue",
          label: "提醒内容"
        }), _createVNode(_component_el_table_column, {
          key: "6432",
          "show-overflow-tooltip": true,
          align: "center",
          look: "6432",
          "min-width": "180px",
          "column-key": "6432",
          prop: "create_dtme",
          width: "auto",
          class: "itemTrue",
          label: "创建时间"
        })]),
        _: 1
      }, 8, ["data", "class", "size", "max-height", "onSelectionChange"])) : (_openBlock(), _createBlock(_component_mobileList, {
        key: 1,
        isDelete: false,
        isEdit: false,
        isDownload: false,
        columns: _unref(columns),
        dataList: _unref(dataList)
      }, null, 8, ["columns", "dataList"]))]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_pagination, {
        total: _unref(page).obj.totalResult,
        "page-size": _unref(page).obj.pageSize,
        "current-page": _unref(page).obj.startPage,
        background: "",
        small: "",
        "page-sizes": [3, 6, 9, 12, 15, 20, 30],
        layout: "sizes,prev, pager, next",
        onSizeChange: _cache[3] || (_cache[3] = $event => _unref(sizeChange)($event, doGetData, _unref(reqData).obj)),
        onCurrentChange: _cache[4] || (_cache[4] = $event => _unref(currentChange)($event, doGetData, _unref(reqData).obj))
      }, null, 8, ["total", "page-size", "current-page"])]), _createElementVNode("div", null, [_createVNode(_component_el_dialog, {
        title: "修改",
        left: "",
        modelValue: _unref(editShow),
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _isRef(editShow) ? editShow.value = $event : editShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "130px",
          "label-position": _unref(getDialogLabelPosition)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "文案名称",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.name,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(currentRow).obj.name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "文案内容",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              rows: "5",
              modelValue: _unref(currentRow).obj.content,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(currentRow).obj.content = $event),
              type: "textarea"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "",
            style: {
              "display": "flex",
              "justify-content": "center"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              size: _ctx.size,
              icon: "select",
              onClick: addSmsWay
            }, {
              default: _withCtx(() => [_createTextVNode("提交")]),
              _: 1
            }, 8, ["size"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["label-position"])]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_dialog, {
        title: "登记用户",
        left: "",
        modelValue: _unref(smsWayShow),
        "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => _isRef(smsWayShow) ? smsWayShow.value = $event : smsWayShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "130px",
          "label-position": _unref(getDialogLabelPosition)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "姓名",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.user_name,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(currentRow).obj.user_name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "省份",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.city,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(currentRow).obj.city = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "单位",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.company,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _unref(currentRow).obj.company = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "目标职称",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.position,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _unref(currentRow).obj.position = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "评审时间",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.review_dtme,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _unref(currentRow).obj.review_dtme = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "收录要求",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "textarea",
              rows: 3,
              modelValue: _unref(currentRow).obj.employ_requirement,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => _unref(currentRow).obj.employ_requirement = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "篇数",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "number",
              modelValue: _unref(currentRow).obj.article_num,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => _unref(currentRow).obj.article_num = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "价格（/篇）",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "number",
              modelValue: _unref(currentRow).obj.price,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => _unref(currentRow).obj.price = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "备注",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.notice,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => _unref(currentRow).obj.notice = $event),
              type: "textarea",
              rows: 3
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "",
            style: {
              "display": "flex",
              "justify-content": "center"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              size: _ctx.size,
              icon: "select",
              onClick: addSmsWay
            }, {
              default: _withCtx(() => [_createTextVNode("提交")]),
              _: 1
            }, 8, ["size"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["label-position"])]),
        _: 1
      }, 8, ["modelValue", "width"])])]);
    };
  }
};