import {
	createApp
} from "vue";
import App from "./App.vue";
import router from "./router/index.js";
const app = createApp(App);
//elelemtPlus以及全部icon
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

app.use(ElementPlus);
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component);
}
import mobileList from "./components/mobileList";
app.use(mobileList);

import {
	Progress,
	Button,
	Col,
	Row,
	TimePicker,
	DatePicker,
	Dialog,
	Popup,
	Picker,
	RadioGroup,
	Radio,
	NavBar,
	Tabbar,
	TabbarItem,
	ActionSheet,
	Icon,
	NoticeBar,
	Loading,
	Empty,
	ImagePreview,
} from "vant";

// 2. 引入组件样式
import "vant/lib/index.css";
// import { Icon } from "@tsingsu/mui";

// 3. 注册你需要的组件
app.use(Button);
app.use(Col);
app.use(Row);
app.use(Dialog);
app.use(TimePicker);
app.use(DatePicker);
app.use(Progress);
app.use(Popup);
app.use(Picker);
app.use(RadioGroup);
app.use(Radio);
app.use(NavBar);
app.use(Tabbar);
app.use(TabbarItem);
app.use(ActionSheet);
app.use(Icon);
app.use(NoticeBar);
app.use(Loading);
app.use(Empty);
app.use(ImagePreview);

//js工具库 _split等
import lodash from "lodash";
window._ = lodash;
//全局注册axios以及默认请求地址，修改成可用地址
import qs from "qs";
app.config.globalProperties.$qs = qs;
import axios from "axios";
app.config.globalProperties.$axios = axios;
// axios.defaults.baseURL = "http://192.168.0.183:3000";
axios.defaults.baseURL =
	window.location.origin == "http://192.168.0.183:8080" ||
	window.location.origin == "http://localhost:8080" ||
	window.location.origin == "http://192.168.0.183:8081" ||
	window.location.origin == "http://localhost:8081" ?
	"http://182.92.83.79:9098/" //http://localhost:3003/
	:
	"https://doc.mutuanzhang.top/docweb";
//组件复用
app.mixin({
	methods: {
		errorMsgBox(notice) {
			this.$message({
				message: notice,
				type: "error",
			});
		},
		successMsgBox(notice) {
			if (!notice) {
				notice = "成功";
			}
			this.$message({
				message: notice,
				type: "success",
			});
		},
		//公共axios
		apiAll(data, api, config = {}) {
			//1、默认api
			if (!api) {
				api = window.http;
			}
			//2、上传地址时使用心得content-type
			if (api == window.fileUpload) {
				//文件上传头Content-Type
				axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
			} else {
				//默认头Content-Type
				axios.defaults.headers.post["Content-Type"] =
					"application/x-www-form-urlencoded";
			}
			//3、默认空参数
			if (!data) {
				const params = {};
				data.params = JSON.stringify(params);
			}
			let qsParam = qs.stringify(data);
			return async () => {
				const {
					method = "POST", error, defaultValue = null
				} = config;
				let res = null;
				try {
					if (method.toUpperCase() === "GET") {
						res = await axios.get(api, {
							params: qsParam,
						});
					} else {
						res = await axios.post(api, qsParam);
					}
					res = res.data;
					return res;
				} catch (e) {
					// this.$message({
					//   message: e.message,
					//   type: "error",
					// });
					if (_.isFunction(error)) {
						await error(e);
					}
					return defaultValue;
				}
			};
		},
		async withLoading(asyncFunc) {
			let res = null;
			try {
				res = await asyncFunc();
			} catch (e) {
				console.error("WithLoading Error: ", e);
			}
			return res;
		},
	},
});
app.use(router).mount("#app");