import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-50e6ae0e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-template-623",
  style: {
    "{\r\n        overflow": "hidden",
    "background-color": "#f4f4f4",
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  style: {
    "width": "60%",
    "margin-right": "10px"
  }
};
const _hoisted_4 = {
  style: {
    "height": "30px",
    "line-height": "30px"
  }
};
const _hoisted_5 = {
  style: {
    "display": "flex",
    "align-items": "center",
    "flex-direction": "column"
  }
};
const _hoisted_6 = {
  style: {
    "margin-bottom": "20px"
  }
};
import { ref, reactive, onMounted, nextTick, watch } from "vue";
import { myMixin } from "../mixin.js";
export default {
  __name: 'doc_contribute',
  setup(__props) {
    const {
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      account,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    let currentRow = reactive({
      obj: {}
    });
    let reqData = reactive({
      obj: {
        url: "/doc/getContribute",
        data: {
          name: "",
          agent_id: ""
        }
      }
    });

    // article_id: String, //文章ID
    //     editor_name: String, //编辑姓名
    //     journal_name: String, //刊名
    //     examine: String, //投稿审核
    //     reback_fix: String, //返修
    //     employment: String, //录用
    //     pay_fee: Number, //支付版面费
    //     expected_publish_dtme: String, //预计出刊时间
    //     is_pubish: String, //是否已经出刊邮寄
    //     is_data_check: String, //是否数据库完成检索

    let columns = reactive([{
      name: "article_id",
      title: "文章ID",
      type: "cascader"
    }, {
      name: "title",
      title: "论文题目"
    }, {
      name: "user_name",
      title: "客户姓名",
      show: 1
    }, {
      name: "editor_name",
      title: "编辑姓名"
    }, {
      name: "journal_name",
      title: "刊名"
    }, {
      name: "examine",
      title: "投稿审核"
    }, {
      name: "employment",
      title: "支付版面费"
    }, {
      name: "pay_fee",
      title: "论文走向定稿需求支付薪资"
    }, {
      name: "expected_publish_dtme",
      title: "预计出刊时间",
      type: "date"
    }, {
      name: "is_pubish",
      title: "是否已经出刊邮寄",
      type: "radio"
    }, {
      name: "is_data_check",
      title: "是否数据库完成检索",
      type: "radio"
    }]);
    let columns1 = reactive([{
      name: "article_id",
      title: "文章ID",
      type: "cascader"
    }, {
      name: "editor_name",
      title: "编辑姓名"
    }, {
      name: "journal_name",
      title: "刊名"
    }, {
      name: "examine",
      title: "投稿审核"
    }, {
      name: "employment",
      title: "支付版面费"
    }, {
      name: "pay_fee",
      title: "论文走向定稿需求支付薪资"
    }, {
      name: "expected_publish_dtme",
      title: "预计出刊时间",
      type: "date"
    }, {
      name: "is_pubish",
      title: "是否已经出刊邮寄",
      type: "radio"
    }, {
      name: "is_data_check",
      title: "是否数据库完成检索",
      type: "radio"
    }]);
    let dataList = reactive({
      obj: []
    });
    let checkAll = ref(false);
    let fieldsShow = ref(false);
    let selectedFields = reactive({
      obj: []
    });
    let exportType = ref(1);
    let options = reactive({
      obj: []
    });
    let optionsCopy = reactive({
      obj: []
    });
    const props = {
      lazy: true,
      multiple: false,
      emitPath: false,
      lazyLoad(node, resolve) {
        const {
          level
        } = node;

        // const nodes = Array.from({ length: level + 1 }).map((item) => ({
        //   value: ++id,
        //   label: `Option - ${id}`,
        //   leaf: level >= 2,
        // }));
        // Invoke `resolve` callback to return the child nodes data and indicate the loading is finished.
        //  if(){

        //  }
        // if (level == 0) {
        //   getAllUser(resolve);
        // } else {
        //   getArticleByOrderNo(node.value, resolve);
        // }
        if (level >= 1) {
          getArticleByOrderNo(node.value, resolve);
        }
      }
    };
    let smsWayShow = ref(false);
    onMounted(() => {
      doGetData();
      doGetAllUser();
    });
    //全选
    function doCheckAll() {
      if (checkAll.value == true) {
        columns.forEach(element => {
          selectedFields.obj.push(element);
        });
      } else {
        selectedFields.obj = [];
      }
    }
    function beforeFilter(value) {
      if (!value) {
        options.obj = optionsCopy.obj;
      } else {
        // 手动筛选
        options.obj = options.obj.filter(v => v.label.indexOf(value) > -1);
        // 空的时候显示默认的筛选无结果面板
        if (options.obj.length === 0) {
          return true;
        }
        // 懒加载时默认筛选无效 直接禁用它 让组件加载我们手动筛选的数据
        return false;
      }
    }
    // 没搜索词时展示完整的原列表数据
    const clear = () => {
      options.obj = optionsCopy.obj;
    };
    function handleChange($event, val) {
      if (!val) {
        options.obj = optionsCopy.obj;
      }
    }
    function showEdit(row) {
      // getAllUser();
      currentRow.obj = row;
      smsWayShow.value = true;
      nextTick(() => {
        let dom = document.getElementsByClassName("user-cascader")[0];
        let inputDom = dom.getElementsByClassName("el-input__inner")[0]; // 搜索框
        if (inputDom) {
          inputDom.addEventListener("input", e => {
            console.log(e.target.value);
            const value = e.target.value;
            if (!value) {
              clear();
            }
          });
        }
      });
    }
    function newWenan() {
      //  getAllUser();
      currentRow.obj = {};
      smsWayShow.value = true;
      nextTick(() => {
        let dom = document.getElementsByClassName("user-cascader")[0];
        let inputDom = dom.getElementsByClassName("el-input__inner")[0]; // 搜索框
        if (inputDom) {
          inputDom.addEventListener("input", e => {
            console.log(e.target.value);
            const value = e.target.value;
            if (!value) {
              clear();
            }
          });
        }
      });
    }
    // async function getAllUser(resolve) {
    //   let data = await withLoading(apiAll({}, "/docUser/getAllUser/"));
    //   let newData = [];
    //   data.forEach((element) => {
    //     element.label = element.user_name;
    //     element.value = element.order_no;
    //     newData.push({ label: element.user_name, value: element.order_no });
    //   });

    //   resolve(newData);
    // }
    async function doGetAllUser() {
      let data = await withLoading(apiAll({}, "/docUser/getAllUser/"));
      let newData = [];
      data.forEach(element => {
        element.label = element.user_name;
        element.value = element.order_no;
        newData.push({
          label: element.user_name,
          value: element.order_no
        });
      });
      optionsCopy.obj = newData;
      options.obj = newData;
    }
    async function getArticleByOrderNo(order_no, resolve) {
      let articles = await withLoading(apiAll({
        order_no: order_no
      }, "/docUser/getArticleByOrderNo/"));
      if (articles && articles.length > 0) {
        articles.forEach(element => {
          element.leaf = true;
          element.label = element.author_name + "(" + element.title + ")";
          element.value = element._id;
        });
      } else {
        articles = [];
      }
      resolve(articles);
    }

    // async function doGetData() {
    //   let data = { agent_id: account._id };
    //   const res = await withLoading(apiAll(data, "/smsWay/getSmsWays"));
    //   dataList.obj = res.items;
    //   page.obj = res.page;
    // }

    async function doGetData() {
      const res = await getData(reqData.obj);
      dataList.obj = res.items;
      page.obj = res.page;
    }
    async function deleteById(id) {
      let data = {};
      const res = await withLoading(apiAll(data, "/doc/deleteContribute/" + id));
      doGetData();
    }
    async function addSmsWay() {
      // if (!currentRow.obj.name) {
      //   errorMsgBox("名称不能为空！");
      //   return false;
      // }
      // if (!currentRow.obj.content) {
      //   errorMsgBox("内容不能为空！");
      //   return false;
      // }
      // let formData = new FormData();
      // if (imgList.obj && imgList.obj.length > 0) {
      //   formData.append("img", imgList.obj[0].raw);
      // }
      // formData.append("params", JSON.stringify(currentRow.obj));
      const res = await withLoading(apiAll(currentRow.obj, "/doc/addContribute"));
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        smsWayShow.value = false;
        doGetData();
      }
    }
    async function exportUser() {
      if (!selectedFields.obj || selectedFields.obj.length <= 0) {
        errorMsgBox("导出字段不能为空");
      } else {
        let data = {};
        data.selectedFields = selectedFields.obj;
        if (exportType.value == 1) {
          //导出当前，给出参数
          data.name = reqData.obj.data.name;
        }
        const res = await withLoading(apiAll(data, "/docExcel/addContributeExcel"));
        if (res.code && res.code < 0) {
          errorMsgBox(res.code);
        } else {
          successMsgBox("导出成功");
          window.open(res.access_path);
        }
      }
    }
    // async function updateSmsWay() {
    //   const res = await withLoading(
    //     apiAll(currentRow.obj, "/smsWenan/updateSmsWay/" + id)
    //   );
    //   doGetData();
    // }

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_mobileList = _resolveComponent("mobileList");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      const _component_el_radio_button = _resolveComponent("el-radio-button");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_el_cascader = _resolveComponent("el-cascader");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        gutter: 5,
        style: {
          margin: '15px 0 15px 0'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 5 : 24,
          style: {
            "display": "flex",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_input, {
            placeholder: "输入名称",
            style: _normalizeStyle({
              height: _unref(agent).obj == 'Windows' ? '33px' : '22px'
            }),
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            modelValue: _unref(reqData).obj.data.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(reqData).obj.data.name = $event),
            clearable: "",
            onKeyup: _cache[1] || (_cache[1] = _withKeys($event => doGetData(), ["enter"])),
            onClear: _cache[2] || (_cache[2] = $event => _unref(clearSearch)(_unref(reqData), doGetData))
          }, null, 8, ["style", "size", "modelValue"])]), _createVNode(_component_el_button, {
            icon: "search",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[3] || (_cache[3] = $event => doGetData())
          }, {
            default: _withCtx(() => [_createTextVNode(" 搜索 ")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 14 : 24,
          style: {
            "text-align": "left",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "plus",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[4] || (_cache[4] = $event => newWenan())
          }, {
            default: _withCtx(() => [_createTextVNode(" 登记投稿 ")]),
            _: 1
          }, 8, ["size"]), _createVNode(_component_el_button, {
            icon: "download",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[5] || (_cache[5] = $event => _isRef(fieldsShow) ? fieldsShow.value = true : fieldsShow = true)
          }, {
            default: _withCtx(() => [_createTextVNode(" 投稿导出 ")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _unref(agent).obj == 'Windows' ? (_openBlock(), _createBlock(_component_el_table, {
        key: 0,
        data: _unref(dataList).obj,
        class: _normalizeClass(_ctx.tableClassNames),
        ref: "table",
        size: _ctx.tableSize,
        "min-height": "100",
        border: false,
        "highlight-current-row": true,
        "max-height": _unref(tableMaxHeight),
        style: {
          "width": "100%"
        },
        showHeader: true,
        onSelectionChange: _ctx.handleSelectionChange,
        "header-cell-style": {
          background: '#eef1f6',
          color: '#606266'
        }
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            "show-overflow-tooltip": true,
            align: "center",
            look: index,
            "min-width": "120px",
            "column-key": index,
            width: "auto",
            class: "itemTrue",
            label: i.title
          }, {
            default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(scope.row[i.name]), 1)]),
            _: 2
          }, 1032, ["look", "column-key", "label"]);
        }), 128)), _createVNode(_component_el_table_column, {
          width: "230",
          align: "center",
          label: "操作",
          fixed: "right"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            size: "small",
            type: "primary",
            text: "",
            link: "",
            icon: "edit",
            onClick: $event => showEdit(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode(" 修改信息 ")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            size: "small",
            type: "danger",
            text: "",
            link: "",
            icon: "delete",
            onClick: $event => deleteById(scope.row._id)
          }, {
            default: _withCtx(() => [_createTextVNode(" 删除 ")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data", "class", "size", "max-height", "onSelectionChange"])) : (_openBlock(), _createBlock(_component_mobileList, {
        key: 1,
        isDelete: true,
        onShowEdit: showEdit,
        onDeleteById: deleteById,
        columns: _unref(columns),
        dataList: _unref(dataList)
      }, null, 8, ["columns", "dataList"]))]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_pagination, {
        total: _unref(page).obj.totalResult,
        "page-size": _unref(page).obj.pageSize,
        "current-page": _unref(page).obj.startPage,
        background: "",
        small: "",
        "page-sizes": [3, 6, 9, 12, 15, 20, 30],
        layout: "sizes,prev, pager, next",
        onSizeChange: _cache[6] || (_cache[6] = $event => _unref(sizeChange)($event, doGetData, _unref(reqData).obj)),
        onCurrentChange: _cache[7] || (_cache[7] = $event => _unref(currentChange)($event, doGetData, _unref(reqData).obj))
      }, null, 8, ["total", "page-size", "current-page"])]), _createElementVNode("div", null, [_createVNode(_component_el_dialog, {
        title: "导出数据",
        left: "",
        modelValue: _unref(fieldsShow),
        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _isRef(fieldsShow) ? fieldsShow.value = $event : fieldsShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("字段选择")]),
          _: 1
        }), _createVNode(_component_el_checkbox, {
          modelValue: _unref(checkAll),
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _isRef(checkAll) ? checkAll.value = $event : checkAll = $event),
          onChange: doCheckAll
        }, {
          default: _withCtx(() => [_createTextVNode(" 全选 ")]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(_component_el_checkbox_group, {
          modelValue: _unref(selectedFields).obj,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(selectedFields).obj = $event),
          style: {
            "margin-bottom": "20px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
            return _openBlock(), _createBlock(_component_el_checkbox, {
              label: i,
              key: index
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(i.title), 1)]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("导出类型")]),
          _: 1
        }), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_radio_group, {
          modelValue: _unref(exportType),
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _isRef(exportType) ? exportType.value = $event : exportType = $event),
          size: _ctx.size
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_button, {
            label: 1
          }, {
            default: _withCtx(() => [_createTextVNode("导出当前")]),
            _: 1
          }), _createVNode(_component_el_radio_button, {
            label: 0
          }, {
            default: _withCtx(() => [_createTextVNode("导出所有")]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "size"])]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("确定导出")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          size: _ctx.size,
          icon: "select",
          onClick: exportUser
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定导出")]),
          _: 1
        }, 8, ["size"])])]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_dialog, {
        title: "登记&修改",
        left: "",
        modelValue: _unref(smsWayShow),
        "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _isRef(smsWayShow) ? smsWayShow.value = $event : smsWayShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "200px",
          "label-position": _unref(getDialogLabelPosition),
          style: {
            "max-height": "400px",
            "overflow": "auto"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns1), (i, index) => {
            return _openBlock(), _createBlock(_component_el_form_item, {
              label: i.title,
              key: index
            }, {
              default: _withCtx(() => [i.type == 'cascader' ? (_openBlock(), _createBlock(_component_el_cascader, {
                key: 0,
                placeholder: _unref(currentRow).obj.title,
                class: "user-cascader",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event,
                "show-all-levels": false,
                props: props,
                options: _unref(options).obj,
                "before-filter": beforeFilter,
                filterable: "",
                clearable: "",
                onChange: $event => handleChange($event, _unref(currentRow).obj[i.name])
              }, {
                default: _withCtx(({
                  node,
                  data
                }) => [_createElementVNode("span", null, _toDisplayString(node.label), 1)]),
                _: 2
              }, 1032, ["placeholder", "modelValue", "onUpdate:modelValue", "options", "onChange"])) : i.type == 'date' ? (_openBlock(), _createBlock(_component_el_date_picker, {
                key: 1,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event,
                type: "date",
                placeholder: "选择日期",
                format: "YYYY-MM-DD",
                "value-format": "YYYY-MM-DD",
                size: _ctx.size
              }, null, 8, ["modelValue", "onUpdate:modelValue", "size"])) : i.type == 'radio' ? (_openBlock(), _createBlock(_component_el_radio_group, {
                key: 2,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event,
                size: _ctx.size
              }, {
                default: _withCtx(() => [_createVNode(_component_el_radio, {
                  label: "是",
                  value: "是"
                }), _createVNode(_component_el_radio, {
                  label: "否",
                  value: "否"
                })]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "size"])) : (_openBlock(), _createBlock(_component_el_input, {
                key: 3,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"]))]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 8, ["label-position"]), _createVNode(_component_el_form_item, {
          style: {
            "margin-left": "200px",
            "display": "flex",
            "justify-content": "center"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            size: _ctx.size,
            icon: "select",
            onClick: addSmsWay
          }, {
            default: _withCtx(() => [_createTextVNode(" 提交")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue", "width"])])]);
    };
  }
};