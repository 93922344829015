import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, isRef as _isRef, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9357939e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-template-623",
  style: {
    "{\n                overflow": "hidden",
    "background-color": "#f4f4f4",
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  style: {
    "width": "60%",
    "margin-right": "10px"
  }
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = ["href"];
const _hoisted_6 = {
  key: 1
};
const _hoisted_7 = {
  style: {
    "height": "30px",
    "line-height": "30px"
  }
};
const _hoisted_8 = {
  key: 3,
  style: {
    "text-align": "center"
  }
};
import VueOfficeDocx from '@vue-office/docx';
import VueOfficeExcel from '@vue-office/excel';
import VueOfficePdf from '@vue-office/pdf';
import '@vue-office/docx/lib/index.css';
import { ref, reactive, onMounted, nextTick } from 'vue';
import { myMixin } from '../mixin.js';
export default {
  __name: 'doc_userWord',
  setup(__props) {
    const {
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      account,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    let loadingData = ref(false);
    let currentRow = reactive({
      obj: {}
    });
    let reqData = reactive({
      obj: {
        url: '/doc/getUserword',
        data: {
          name: '',
          agent_id: ''
        }
      }
    });

    // article_id: String, //文章ID
    //     editor_name: String, //编辑姓名
    //     journal_name: String, //刊名
    //     examine: String, //投稿审核
    //     reback_fix: String, //返修
    //     employment: String, //录用
    //     pay_fee: Number, //支付版面费
    //     expected_publish_dtme: String, //预计出刊时间
    //     is_pubish: String, //是否已经出刊邮寄
    //     is_data_check: String, //是否数据库完成检索

    let columns = reactive([{
      name: 'order_no',
      title: '订单号',
      minWidth: '80px'
    }, {
      name: 'user_name',
      title: '客户姓名'
    }, {
      name: 'title',
      title: '论文题目'
    }, {
      name: 'file_name',
      title: '文件名',
      minWidth: '250px'
    }, {
      name: 'access_path',
      title: '客户资料',
      type: 'file'
    }]);
    let editColumns = reactive([{
      name: 'order_no',
      title: '订单号'
    }, {
      name: 'access_path',
      title: '客户资料',
      type: 'file'
    }]);
    let imgList = reactive({
      obj: []
    });
    let user = reactive({
      obj: []
    });
    let dataList = reactive({
      obj: []
    });
    let srcList = reactive({
      obj: []
    });
    let currentFile = reactive({
      obj: {}
    });
    let showFile = ref(false);
    let smsWayShow = ref(false);
    let currentTxt = ref();
    let sourceUrl = 'https://doc.mutuanzhang.top/resources/';
    onMounted(() => {
      doGetData();
      getAllUser();
    });
    // function previewImg(url) {
    //   dialogImageUrl.value = url;
    //   srcList.obj = [];
    //   srcList.obj.push(url);
    //   nextTick(() => {
    //     document.querySelector("#show-image").click();
    //     //获取到的是数据更新后的Dom数据
    //   });
    // }
    async function preview(row) {
      currentFile.obj = row;
      currentFile.obj.expandName = currentFile.obj.file_name.split('.').pop();
      currentFile.obj.url = sourceUrl + currentFile.obj.file_path.split('/').pop();
      currentFile.obj.access_path = currentFile.obj.url;
      if (currentFile.obj.expandName == 'png' || currentFile.obj.expandName == 'jpg' || currentFile.obj.expandName == 'jpeg' || currentFile.obj.expandName == 'svg' || currentFile.obj.expandName == 'webp') {
        srcList.obj = [];
        srcList.obj.push(currentFile.obj.url);
        nextTick(() => {
          document.querySelector('#show-image').click();
          //获取到的是数据更新后的Dom数据
        });
      } else {
        if (currentFile.obj.expandName == 'txt') {
          await fetchTextFile(currentFile.obj.access_path);
        } else if (currentFile.obj.expandName == 'doc') {
          // await readExcelFromRemoteFile(currentFile.obj.access_path);
        }
        showFile.value = true;
      }
    }
    async function fetchTextFile(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        currentTxt.value = await response.text();
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      }
    }
    function showEdit(row) {
      imgList.obj.push[{
        url: row.access_path
      }];
      currentRow.obj = row;
      smsWayShow.value = true;
    }
    function newWenan() {
      currentRow.obj = {};
      imgList.obj = [];
      smsWayShow.value = true;
    }
    async function doGetData() {
      const res = await getData(reqData.obj);
      dataList.obj = res.items;
      page.obj = res.page;
    }
    async function deleteById(id) {
      let data = {};
      const res = await withLoading(apiAll(data, '/doc/deleteUserWord/' + id));
      doGetData();
    }
    async function getAllUser(resolve) {
      user.obj = await withLoading(apiAll({}, '/docUser/getAllUser/'));
    }
    async function addSmsWay() {
      // if (!currentRow.obj.name) {
      //   errorMsgBox("名称不能为空！");
      //   return false;
      // }
      // if (!currentRow.obj.content) {
      //   errorMsgBox("内容不能为空！");
      //   return false;
      // }
      let formData = new FormData();
      if (imgList.obj && imgList.obj.length > 0) {
        formData.append('img', imgList.obj[0].raw);
      }
      formData.append('params', JSON.stringify(currentRow.obj));
      loadingData.value = true;
      const res = await withLoading(formDataApi(formData, '/doc/addUserWord'));
      loadingData.value = false;
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        smsWayShow.value = false;
        doGetData();
      }
    }
    async function exportAuthor() {
      let data = {};
      const res = await withLoading(apiAll(data, '/docExcel/addAuthorExcel/'));
      if (res.code && res.code < 0) {
        errorMsgBox(res.code);
      } else {
        successMsgBox('导出成功');
        // let access_path = res.access_path;
        window.open(res.access_path);
      }
    }
    // async function updateSmsWay() {
    //   const res = await withLoading(
    //     apiAll(currentRow.obj, "/smsWenan/updateSmsWay/" + id)
    //   );
    //   doGetData();
    // }

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_mobileList = _resolveComponent("mobileList");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_Plus = _resolveComponent("Plus");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_el_image = _resolveComponent("el-image");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        gutter: 5,
        style: {
          margin: '15px 0 15px 0'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 5 : 24,
          style: {
            "display": "flex",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_input, {
            placeholder: "输入名称",
            style: _normalizeStyle({
              height: _unref(agent).obj == 'Windows' ? '33px' : '22px'
            }),
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            modelValue: _unref(reqData).obj.data.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(reqData).obj.data.name = $event),
            clearable: "",
            onKeyup: _cache[1] || (_cache[1] = _withKeys($event => doGetData(), ["enter"])),
            onClear: _cache[2] || (_cache[2] = $event => _unref(clearSearch)(_unref(reqData), doGetData))
          }, null, 8, ["style", "size", "modelValue"])]), _createVNode(_component_el_button, {
            icon: "search",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[3] || (_cache[3] = $event => doGetData())
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 14 : 24,
          style: {
            "text-align": "left",
            "margin-bottom": "10px"
          }
        }, null, 8, ["span"])]),
        _: 1
      }), _unref(agent).obj == 'Windows' ? (_openBlock(), _createBlock(_component_el_table, {
        key: 0,
        data: _unref(dataList).obj,
        class: _normalizeClass(_ctx.tableClassNames),
        ref: "table",
        size: _ctx.tableSize,
        "min-height": "100",
        border: false,
        "highlight-current-row": true,
        "max-height": _unref(tableMaxHeight),
        style: {
          "width": "100%"
        },
        showHeader: true,
        onSelectionChange: _ctx.handleSelectionChange,
        "header-cell-style": {
          background: '#eef1f6',
          color: '#606266'
        }
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            "show-overflow-tooltip": true,
            align: "center",
            look: index,
            "min-width": i.minWidth ? i.minWidth : '180px',
            "column-key": index,
            width: "auto",
            class: "itemTrue",
            label: i.title
          }, {
            default: _withCtx(scope => [i.name == 'access_path' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_button, {
              text: "",
              link: "",
              icon: "download"
            }, {
              default: _withCtx(() => [_createElementVNode("a", {
                href: scope.row.access_path
              }, "下载", 8, _hoisted_5)]),
              _: 2
            }, 1024), scope.row.file_name.split('.').pop() !== 'rar' && scope.row.file_name.split('.').pop() !== 'zip' ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              style: {
                "margin-left": "10px"
              },
              text: "",
              link: "",
              icon: "view",
              onClick: $event => preview(scope.row)
            }, {
              default: _withCtx(() => [_createTextVNode(" 预览 ")]),
              _: 2
            }, 1032, ["onClick"])) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(scope.row[i.name]), 1))]),
            _: 2
          }, 1032, ["look", "min-width", "column-key", "label"]);
        }), 128)), _createVNode(_component_el_table_column, {
          width: "230",
          align: "center",
          label: "操作",
          fixed: "right"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            size: "small",
            type: "danger",
            text: "",
            link: "",
            icon: "delete",
            onClick: $event => deleteById(scope.row._id)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data", "class", "size", "max-height", "onSelectionChange"])) : (_openBlock(), _createBlock(_component_mobileList, {
        key: 1,
        isDelete: true,
        isEdit: false,
        isPreview: true,
        onPreview: preview,
        onDeleteById: deleteById,
        columns: _unref(columns),
        dataList: _unref(dataList)
      }, null, 8, ["columns", "dataList"]))]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_pagination, {
        total: _unref(page).obj.totalResult,
        "page-size": _unref(page).obj.pageSize,
        "current-page": _unref(page).obj.startPage,
        background: "",
        small: "",
        "page-sizes": [3, 6, 9, 12, 15, 20, 30],
        layout: "sizes,prev, pager, next",
        onSizeChange: _cache[4] || (_cache[4] = $event => _unref(sizeChange)($event, doGetData, _unref(reqData).obj)),
        onCurrentChange: _cache[5] || (_cache[5] = $event => _unref(currentChange)($event, doGetData, _unref(reqData).obj))
      }, null, 8, ["total", "page-size", "current-page"])]), _createElementVNode("div", null, [_createVNode(_component_el_dialog, {
        title: "登记客户资料",
        left: "",
        modelValue: _unref(smsWayShow),
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _isRef(smsWayShow) ? smsWayShow.value = $event : smsWayShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_form, {
          "label-width": "130px",
          "label-position": _unref(getDialogLabelPosition),
          style: {
            "max-height": "400px",
            "overflow": "auto"
          },
          "element-loading-text": "拼命上传中"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(editColumns), (i, index) => {
            return _openBlock(), _createBlock(_component_el_form_item, {
              label: i.title,
              key: index
            }, {
              default: _withCtx(() => [i.name == 'order_no' ? (_openBlock(), _createBlock(_component_el_select, {
                key: 0,
                modelValue: _unref(currentRow).obj.order_no,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(currentRow).obj.order_no = $event),
                placeholder: "选择订单",
                size: "large",
                style: {
                  "width": "240px"
                }
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(user).obj, item => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: item.order_no,
                    label: item.user_name,
                    value: item.order_no
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])) : i.type == 'file' ? (_openBlock(), _createBlock(_component_el_upload, {
                key: 1,
                style: {
                  "display": "inline-block"
                },
                action: "#",
                limit: "1",
                "auto-upload": false,
                "on-change": _ctx.handleChange,
                "file-list": _unref(imgList).obj,
                "onUpdate:fileList": _cache[7] || (_cache[7] = $event => _unref(imgList).obj = $event)
              }, {
                default: _withCtx(() => [_createVNode(_component_el_icon, {
                  class: "avatar-uploader-icon"
                }, {
                  default: _withCtx(() => [_createVNode(_component_Plus)]),
                  _: 1
                })]),
                _: 1
              }, 8, ["on-change", "file-list"])) : (_openBlock(), _createBlock(_component_el_input, {
                key: 2,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"]))]),
              _: 2
            }, 1032, ["label"]);
          }), 128)), _createVNode(_component_el_form_item, {
            label: "",
            style: {
              "display": "flex",
              "justify-content": "center"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              size: _ctx.size,
              icon: "select",
              onClick: addSmsWay
            }, {
              default: _withCtx(() => [_createTextVNode("提交")]),
              _: 1
            }, 8, ["size"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["label-position"])), [[_directive_loading, _unref(loadingData)]])]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_dialog, {
        modelValue: _unref(showFile),
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _isRef(showFile) ? showFile.value = $event : showFile = $event),
        fullscreen: true,
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_unref(currentFile).obj.expandName == 'doc' || _unref(currentFile).obj.expandName == 'docx' ? (_openBlock(), _createBlock(_unref(VueOfficeDocx), {
          key: 0,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : _unref(currentFile).obj.expandName == 'xlx' || _unref(currentFile).obj.expandName == 'xlsx' ? (_openBlock(), _createBlock(_unref(VueOfficeExcel), {
          key: 1,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : _unref(currentFile).obj.expandName == 'pdf' ? (_openBlock(), _createBlock(_unref(VueOfficePdf), {
          key: 2,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_withDirectives(_createElementVNode("textarea", {
          style: {
            "border": "none"
          },
          "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _isRef(currentTxt) ? currentTxt.value = $event : currentTxt = $event),
          readonly: "",
          rows: "20",
          cols: "80"
        }, null, 512), [[_vModelText, _unref(currentTxt)]])]))]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_image, {
        id: "show-image",
        style: {
          "width": "0",
          "height": "0"
        },
        src: _unref(currentFile).obj.url,
        "zoom-rate": 1.2,
        "max-scale": 7,
        "min-scale": 0.2,
        "preview-src-list": _unref(srcList).obj,
        "initial-index": _unref(srcList).obj.length - 1,
        fit: "cover"
      }, null, 8, ["src", "preview-src-list", "initial-index"])])]);
    };
  }
};